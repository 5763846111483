@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
/*font-family: 'Nunito', sans-serif;*/
/*font-family: 'DM Mono', monospace;*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tooltip {
  position: absolute;
  padding: 8px;
  font-weight: 500;
  background: rgba(56, 58, 61, 0.9);
  opacity: 0.94;
  border-radius: 4px;
  font-size: 11px;
  line-height: 13px;
  color: white;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.tooltip span {
  font-weight: normal;
}

.fullWidth {
  width: 100%;
}
